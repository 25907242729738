<template>
  <div v-loading="loading">
    <component :is="homeComponent" v-if="colList.length" />
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      homeComponent: (resolve) => require([`./components/${this.$store.getters.moduleName}`], resolve),
      colList: [],
      loading: true
    }
  },
  watch: {
  },
  created() {
    this.getColId()
  },
  methods: {
    getColId() {
      this.loading = true
      this.$store.dispatch('home/HomeGetColum').then(res => {
        if (res.code === 0) {
          this.colList = res.data
          this.$store.state.app.colList = res.data
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>

